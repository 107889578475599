<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- headder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-key</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("admin.genkey") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- Detail -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontainersetemailadmin">
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <br />
                  <div v-if="loaddataprogresstable === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <!-- Phone -->
                    <!-- <div v-if="resolutionScreen < 500"></div> -->
                    <!-- Labtop -->
                    <div>
                      <v-layout justify-end>
                        <v-flex lg1 class="text-center">
                          <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_createBypassAuthorize">สร้างคีย์</v-btn>
                        </v-flex>
                      </v-layout>
                      <v-layout class="pt-6">
                        <v-flex>
                          <v-card>
                            <v-card-title>Credential</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                              <p>Credential authorize : {{ bypassAuthorize }}</p>
                            </v-card-text>
                            <!-- สำหรับ Key ส่งมาแบบ Obj ------------------------------------------------------------------------------------- -->
                            <!-- <v-card-text v-for="(item, index) in bypassAuthorizeObj" :key="index">
                              <v-layout wrap>
                                <v-flex lg12 style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                                  <v-row>
                                    <v-col
                                      ><span>bypass authorize : {{ item.bypassAuthorize }}</span></v-col
                                    >
                                    <v-col
                                      ><span>expire: {{ item.expire }}</span></v-col
                                    >
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <span>service: {{ item.serviceAuthorization }}</span>
                                    </v-col>
                                  </v-row>
                                </v-flex>
                              </v-layout>
                              <v-divider></v-divider>
                            </v-card-text> -->
                            <!-- --------------------------------------------------------------------------------------------------------------- -->
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </div>
                    <br />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
            <!-- <editemail
              :show="opendialogeditemail"
              :detaildata="detaildata"
              :email_all="email"
              @close="(opendialogeditemail = false), fn_get_email()"
            ></editemail>
            <adminrightclick
              :show="showadminMenu"
              :AreaX="x"
              :AreaY="y"
              @closeinboxmenu="showadminMenu = false"
              :email="currentemail"
              :email_all="email"
              :file="[]"
              @deleteemail="fn_confirm_delete"
            ></adminrightclick> -->
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import gbfGenerate from "../globalFunctions/generateAuthorize";

// const adminrightclick = () => import("../components/contextmenu/adminrightclick");
// const editemail = () => import("../components/optional/dialog-editemail");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
    // editemail,
    // adminrightclick,
  },
  data: function() {
    return {
      bypassAuthorizeObj: [],
      bypassAuthorize: "-",
      currentemail: "",
      showadminMenu: false,
      email: [],
      email_all: [],
      opendialogeditemail: false,
      loaddataprogresstable: false,
      detaildata: {},
      loaddataprogress: true,
      opendetaillog: false,
      opendownloadlog: false,
      size: 20,
      offset: 0,
      page: 1,
      x: 0,
      y: 0,
      listsize: [10, 20, 50, 100],
      header: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
        {
          text: "admin.emailtext",
          align: "",
          value: "email",
          width: "86%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "status",
          width: "7%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.email.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.email.slice(start, start + this.size);
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {  
    async fn_getBypassAuthorize() {
      this.loaddataprogresstable = true;
      // ขอ authorize
      let auth = await gbfGenerate.generateToken();
      // console.log(process.env.VUE_APP_SERVICE_ADMIN+`api/admin/show_bypass?account_admin=${this.dataAccountId}&business_id=${this.dataAccountActive.business_info.business_id}`)
      this.axios
        .get(
          process.env.VUE_APP_SERVICE_ADMIN +
            `api/admin/show_bypass?account_admin=${this.dataAccountId}&business_id=${this.dataAccountActive.business_info.business_id}`,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // set bypass autorize
            this.bypassAuthorize = response.data.bypass_authorization;
            // Sudo code for obj --------------------------------------------------------------------------------------------------------------------------------------------------
            // let setData = {
            //   bypassAuthorize: "-",
            //   expire: "-",
            //   serviceAuthorization: "-",
            // };
            // for (let index = 0; index < 3; index++) {
            //   const element = response.data[index] || "-";
            //   setData.bypassAuthorize = element.bypass_authorization || "-";
            //   setData.expire = element.expire || "-";
            //   setData.serviceAuthorization = element.serviceAuthorization || "-";
            //   this.bypassAuthorizeObj.push(setData);
            // }
            // console.log("Fake Obj", this.bypassAuthorizeObj);
            // --------------------------------------------------------------------------------------------------------------------------------------------------------------------
          } else {
            // Not OK
            console.log(response.data.status);
            this.bypassAuthorize = "-";
          }
          this.loaddataprogresstable = false;
        });
    },
    async fn_createBypassAuthorize() {
      this.loaddataprogresstable = true;
      let auth = await gbfGenerate.generateToken();
      let payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "api/admin/generate_bypass", payload, { headers: { Authorization: auth.code } })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("Create Key Complete");
          } else {
            console.log(`Error : ${response.data.status}`);
          }
          // เรียกตัวอัปเดตคีย์
          this.fn_getBypassAuthorize();
          this.loaddataprogresstable = false;
        });
    },
  },
  mounted() {
   // this.fn_get_email();
    this.fn_getBypassAuthorize();
    this.loaddataprogress = false;
  },
};
</script>
<style>
#thiscontainersetemailadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
